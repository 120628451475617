// react imports
import React, { useState } from "react";

// prime imports
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { Calendar } from 'primereact/calendar';

// other 3rd party components
import ReactTooltip from 'react-tooltip'

// custom components
import TextInputDialog from "../../../common/dialogs/text-input-dialog/TextInputDialog";
import TextAreaDialog from "../../../common/dialogs/textarea-dialog/TextAreaDialog";

// sample
import { ACTIVITIES, IMPLEMENTATION_STATUSES, INSTITUTIONS, CURRENCIES, SDGs } from "../../../../apis/sampleData";
import { getImplementationStatusTemplate } from "../../../../apis/templates";

// styles
import "./RecommendationDetailsContent.scss"

// sdg images
import sdgBadge1 from "../../.././../imgs/sdgs/1.png";
import sdgBadge2 from "../../.././../imgs/sdgs/2.png";
import sdgBadge3 from "../../.././../imgs/sdgs/3.png";
import sdgBadge4 from "../../.././../imgs/sdgs/4.png";
import sdgBadge5 from "../../.././../imgs/sdgs/5.png";
import sdgBadge6 from "../../.././../imgs/sdgs/6.png";
import sdgBadge7 from "../../.././../imgs/sdgs/7.png";
import sdgBadge8 from "../../.././../imgs/sdgs/8.png";
import sdgBadge9 from "../../.././../imgs/sdgs/9.png";
import sdgBadge10 from "../../.././../imgs/sdgs/10.png";
import sdgBadge11 from "../../.././../imgs/sdgs/11.png";
import sdgBadge12 from "../../.././../imgs/sdgs/12.png";
import sdgBadge13 from "../../.././../imgs/sdgs/13.png";
import sdgBadge14 from "../../.././../imgs/sdgs/14.png";
import sdgBadge15 from "../../.././../imgs/sdgs/15.png";
import sdgBadge16 from "../../.././../imgs/sdgs/16.png";
import sdgBadge17 from "../../.././../imgs/sdgs/17.png";

/**
 * @param mechanismTitle: string
 * @param dateString: string
 * @param recommendation: RECOMMENDATION
 * @returns 
 */
const RecommendationDetailsContent = (props) => {
  // const
  const sdgImages = [
    sdgBadge1,
    sdgBadge2,
    sdgBadge3,
    sdgBadge4,
    sdgBadge5,
    sdgBadge6,
    sdgBadge7,
    sdgBadge8,
    sdgBadge9,
    sdgBadge10,
    sdgBadge11,
    sdgBadge12,
    sdgBadge13,
    sdgBadge14,
    sdgBadge15,
    sdgBadge16,
    sdgBadge17,
  ];

  return (
    <div className="single-element-tab-content details-content">
      <p className="mechanism-title">
        {props.mechanismTitle}
      </p>
      <a className="date-string" href="https://google.com">
        {props.dateString}
        <i className="fa-regular fa-arrow-up-right-from-square"></i>
      </a>
      <p className="title inline-edit-block">
        <span>{props.recommendation.title}</span>
      </p>

      <div className="recommendation-properties">
        <div className="property-field">
          <div className="property-field-title">
            Affected Persons
          </div>
          <div className="property-field-value list">
            <ul>
              {props.recommendation.affectedPersons.map((person) => (
                <li key={person}>
                  {person}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="property-field">
          <div className="property-field-title">
            Themes
          </div>
          <div className="property-field-value list">
            <ul>
              {props.recommendation.themes.map(theme => (
                <li key={theme.id}>
                  {theme.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="recommendation-sdgs">
        <div className="recommendation-sdg--title">
          Sustainable Development Goals
        </div>

        {props.recommendation.sdgs.map(sdg => (
          <div className="sdg-column" key={sdg.index}>
            <img src={sdgImages[sdg.index - 1]} alt={sdg.title} data-tip={SDGs[sdg.index - 1].title} />

            {sdg.sugSdgIndexes.map(subSdgIndex => (
              <div
                className="sub-sdg"
                key={subSdgIndex}
                style={{ backgroundColor: SDGs[sdg.index - 1].color }}
                data-tip={SDGs[sdg.index - 1].subSdgs[subSdgIndex]}
              >
                {SDGs[sdg.index - 1].subSdgs[subSdgIndex].split('-')[0]}
              </div>
            ))}
          </div>
        ))}
      </div>

      <ReactTooltip
        place="top"
        type="light"
        className="nrtd-tooltip"
        html={true}
      />
    </div>
  );
};

export default RecommendationDetailsContent;