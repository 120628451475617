// react imports
import React from "react";

// custom components
import Hero from "../common/hero/Hero";

// imgs
import heroImg from "../../imgs/human-rights-mechanisms/human-rights-hero.jpg";

// styles
import "./About.scss"

const About = (props) => {
  return (
    <div className="page-wrapper about-page">
      <div className="container">
        <Hero
          title="About"
          backgroundImage={heroImg}
        />

        <div className="narrow-section">
          <h2>Lorem ipsum dolor sit amet</h2>

          <p>His possim molestie tacimates ne. Eu usu quodsi repudiare. Sed duis mucius explicari no, an aliquid deserunt disputando sea, his eu odio dico molestiae. Velit harum torquatos vim at. Aliquid detraxit ad pri.</p>

          <p>Quem porro consetetur te eos. Eos soleat menandri expetenda an. Duo in possit bonorum fabulas, alia ornatus mandamus in vis, accumsan albucius delectus nam in. Tation invenire oportere pro eu. Nostro gloriatur vel at.</p>

          <h2>Est modo omnes nusquam an</h2>

          <p>Euismod efficiantur ei qui, est insolens convenire repudiandae ei. Nam sint adipisci in, in eirmod postulant conclusionemque vis, in accusata nominati tincidunt mea. Est exerci nostro eligendi et, nec ad aperiri delicatissimi. Id eros torquatos scribentur quo, munere ridens te nec. Vide mundi mnesarchum eos at, et delenit adolescens eos.</p>

          <p>Has etiam nostrud an, ad eam solet integre, no vel dolorum adversarium definitionem. Cum legere perfecto an. Nec ut diam eros probo. Essent definitiones ad vim. Ad mucius latine moderatius mea, ius in dicant reprehendunt.</p>

          <h3>Ipsum deseruisse deterruisset vix eu</h3>

          <p>Usu dicit persius lobortis ad, an reque senserit per. Ut omnesque ocurreret vim, an brute vidisse gloriatur est. Ipsum deleniti consulatu ea est, eam labore contentiones ex. Cum solum doming temporibus no, movet nominavi eos ei.</p>
        </div>
      </div>
    </div>
  );
};

export default About;