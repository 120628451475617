// react imports
import React from "react";
import { Link, NavLink } from "react-router-dom";

// primereact components
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

// custom components
import Hero from "../../common/hero/Hero";

// sample data
import { SPECIAL_PROCEDURES } from "../../../apis/sampleData";

// styles
import "./SpecialProcedures.scss"

// images
import specialProceduresHero from "../../../imgs/special-procedures/special-procedures-hero.jpg";

const SpecialProcedures = () => {

  const getStatusOfVisitCell = (rowData) => {
    return (
      <>
        { rowData.status_of_visit }
        {rowData.status_of_visit_link && (
          <a href={ rowData.status_of_visit_link } target="_blank">
            <i className="fa-regular fa-arrow-up-right-from-square"></i>
          </a>
        )}
      </>
    );
  };

  return (
    <div className="page-wrapper mechanism-page special-procedures">
      <div className="container">
        <Hero
          title='Special Procedures'
          backgroundImage={specialProceduresHero}
          displayBackLink={true} 
        />

        <div className="mechanism-columns">
          <div className="mechanism-content">
            <DataTable value={SPECIAL_PROCEDURES}>
              <Column className="mandate-holders" header="Special Procedure Mandate-Holders" field="mandate_holders" sortable></Column>
              <Column className="status-of-visit" header="Status of visit" field="status_of_visit" body={getStatusOfVisitCell} sortable></Column>
              <Column className="date" header="Dates" field="dates" sortable></Column>
            </DataTable>
          </div>
  
          <div className="mechanism-side-panel">
            <div className="side-panel-info">
              <label>Standing invitations</label>
              <div className="value">Yes</div>
            </div>

            <div className="side-panel-info">
              <label>Date of invitation</label>
              <div className="value">02/04/2023</div>
            </div>

            <div className="side-panel-info">
              <div className="value">
                Explore 114 recommendations received by Switterland from Special Procedure Mandate Holders
              </div>
            </div>

            <NavLink to="../../recommendations" className="blue-button">
              Recommendations
              <i className="fa-light fa-arrow-up-right"></i>
            </NavLink>
          </div>
        </div>

      </div>
    </div>
  );
};

export default SpecialProcedures;