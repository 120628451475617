// react imports
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router";

// prime imports
import { Button } from "primereact/button";

// custom components
import ActionMenu from "../../common/action-menu/ActionMenu";
import Updates from "../../common/updates/Updates";
import ProjectDetailsContent from "./project-details-content/ProjectDetailsContent";
import ProjectSidepanel from "./project-sidepanel/ProjectSidepanel";
import RecommendationList from "../../recommendations/recommendation-list/RecommendationList";
import RecommendationsFilterPanel from "../../recommendations/recommendations-filter-panel/RecommendationsFilterPanel";
import ActivitiesFilterPanel from "../../activities/activities-filter-panel/ActivitiesFilterPanel";
import ActivitiesList from "../../activities/activities-list/ActivitiesList";
import Hero from "../../common/hero/Hero";

// sample
import { PROJECTS, UPDATES, ACTIVITIES, RECOMMENDATIONS, EMPTY_PROJECT } from "../../../apis/sampleData";

// imgs
import addListsImg from "../../../imgs/add-lists.png";
import heroImg from "../../../imgs/human-rights-mechanisms/human-rights-hero.jpg";

// styles
import "./ProjectPage.scss"

const ProjectPage = () => {
  let navigate = useNavigate();

  // react helpers
  const params = useParams();

  // content
  const project = (params.projectId !== 'empty') ?
    PROJECTS.find(p => p.id === parseInt(params.projectId)) :
    EMPTY_PROJECT;

  // states
  const [selectedTab, setSelectedTab] = useState('Details');
  const [itemsTab, setItemsTab] = useState('Recommendations');
  const [displayListsForEmpty, setDisplayListsForEmpty] = useState(false);

  // helper funcs
  const getAddListsPanel = () => (
    <div className="projects-add-lists">
      <img src={addListsImg} alt="Add List" />
      <h2>Add recommendations or activities to your project</h2>
      <p>Lorem ipsum dolor sit amet eripteum sit dolor</p>

      <Button
        label="Add recommendations or activities"
        onClick={() => setDisplayListsForEmpty(true)}
      />
    </div>
  );
  const getLists = () => (
    <div className="project-items">
      <div className="project-items-top-row">
        <div className="project-items-tabs">
          <Button
            label="Recommendations"
            className={`p-button-text ${itemsTab !== "Recommendations" && 'p-button-secondary'}`}
            badge="32"
            onClick={(e) => setItemsTab('Recommendations')}
          />

          <Button
            label="Activities"
            className={`p-button-text ${itemsTab !== "Activities" && 'p-button-secondary'}`}
            badge="12"
            onClick={(e) => setItemsTab('Activities')}
          />
        </div>

        <div className="project-items-specific-links">
          {/* {itemsTab === "Activities" && (
            <>
              <Button
                icon="fa-regular fa-clipboard-check"
                label="Link activitiy - Törölni kéne"
                className="p-button-text link-activity"
                onClick={() => console.log('Törölni kell')}
              />

              <Button
                icon="fa-solid fa-notes-medical"
                label="Create Activity - törölni kell"
                onClick={() => console.log('Törölni kell')}
              />
            </>
          )} */}
        </div>
      </div>

      {itemsTab === "Recommendations" && (
        <>
          <RecommendationsFilterPanel extended={false} />

          <RecommendationList
            recommendationListHeight={1000}
            recommendationClickable={false}
          />
        </>
      )}

      {itemsTab === "Activities" && (
        <>
          <ActivitiesFilterPanel />

          <ActivitiesList
            activities={ACTIVITIES}
            activitiesListHeight={1000}
            sortBy="IMPLEMENTATION STATUS"
          />
        </>
      )}
    </div>
  );

  return (
    <div className="page-wrapper project">
      <div className="container">
        <Hero
          title={`Follow-up activity: ${project.name}`}
          backgroundImage={heroImg}
        />

        <div className="single-element project narrow-section">
          <div className="single-element-header">
            <div>
              <Button
                icon="fa-regular fa-circle-chevron-left"
                className="p-button-text p-button-secondary"
                onClick={() => navigate(-1)}
              />

              <div className="single-element-tabs">
                <Button
                  label="Details"
                  className={`p-button-text ${selectedTab === 'Details' ? '' : 'p-button-secondary'}`}
                  onClick={() => setSelectedTab('Details')}
                />

                <Button
                  label="Updates"
                  className={`p-button-text ${selectedTab === 'Updates' ? '' : 'p-button-secondary'}`}
                  onClick={() => setSelectedTab('Updates')}
                />
              </div>
            </div>

            <div>
              <Button
                icon="fa-regular fa-arrow-up-right-from-square"
                label="Export"
                className="p-button-text p-button-secondary"
                onClick={() => console.log("export")}
              />

              {/* <ActionMenu
                actions={[
                  { name: 'Project permissions' },
                  { name: 'Delete Project' }
                ]}
                actionSelected={(selectedActionName => console.log(selectedActionName))}
              /> */}
            </div>
          </div>

          <div className="single-element-content">
            {selectedTab === "Details" && <ProjectDetailsContent project={project} />}

            {selectedTab === "Updates" && <Updates updateItems={UPDATES} />}

            <ProjectSidepanel project={project} />
          </div>
        </div>

        <div className="narrow-section">
          {selectedTab === 'Details' && (!displayListsForEmpty && params.projectId === 'empty') && getAddListsPanel()}
  
          {selectedTab === 'Details' && (displayListsForEmpty || params.projectId !== 'empty') && getLists()}
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;