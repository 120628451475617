export const getImplementationStatusTemplate = implementationStatus => {
  return (
    <div className="implementation-status-template">
      <div className={"status-bars " + implementationStatus.replace(/ /g, '-').toLowerCase()}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {implementationStatus}
    </div>
  );
};

export const PAGINATOR_TEMPLATE = {
  layout: 'PrevPageLink PageLinks NextPageLink',
  'PrevPageLink': (options) => {
    return (
      <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
        <i className="fa-regular fa-angles-left"></i>
        Prev
      </button>
    )
  },
  'NextPageLink': (options) => {
    return (
      <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
        Next
        <i className="fa-regular fa-angles-right"></i>
      </button>
    )
  }
};
