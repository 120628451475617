// react imports
import React, { useRef } from "react";
import { Link, NavLink } from "react-router-dom";

// custom components
import Hero from "../common/hero/Hero";

// styles
import "./Mechanisms.scss"

// images
import mechanismsHero from "../../imgs/human-rights-mechanisms/human-rights-hero.jpg";
import whatAreMechanismsImg from "../../imgs/human-rights-mechanisms/human-rights-what-are.jpg";
import uprImg from "../../imgs/human-rights-mechanisms/human-rights-upr.jpg";
import specialProceduresImg from "../../imgs/human-rights-mechanisms/human-rights-special-procedures.jpg";
import treatyBodiesImg from "../../imgs/human-rights-mechanisms/human-rights-treaty-bodies.jpg";
import iccprImg from "../../imgs/human-rights-mechanisms/human-rights-iccpr.jpg";
import cescrImg from "../../imgs/human-rights-mechanisms/human-rights-cescr.jpg";

const Mechanisms = (props) => {
  // refs
  const uprRef = useRef(null);
  const specialProceduresRef = useRef(null);
  const treatyBodiesRef = useRef(null);

  // consts
  const heroLinks = [
    {
      title: 'Universal Periodic Review',
      ref: uprRef
    },
    {
      title: 'Special Procedures',
      ref: specialProceduresRef
    },
    {
      title: 'Treaty Bodies',
      ref: treatyBodiesRef
    }
  ];

  return (
    <div className="page-wrapper mechanisms">
      <div className="container">
        <Hero 
          title='Human rights mechanisms'
          backgroundImage={mechanismsHero}
          heroLinks={heroLinks} />

          <div className="mechanism narrow-section">
            <header>
              <img src={whatAreMechanismsImg} alt="What are human rights mechanisms?" />
            </header>

            <h2>What are human rights mechanisms?</h2>

            <p>The international human rights mechanisms are monitoring bodies in charge of assessing the human rights situation of States across the world. These mechanisms engage with States and multiple stakeholders by considering reports, undertaking country visits, and issuing recommendations for States to improve their human rights record.</p>

            <p>The human rights monitoring mechanisms within the United Nations system include the Universal Periodic Review (UPR), the Special Procedures of the Human Rights Council and the Treaty Bodies. As the leading UN entity on human rights, the <a href="https://www.google.com" target="_blank">UN Human Rights Office</a> supports the work of these mechanisms through guidance and expertise.</p>

            <a className="blue-button">
              Read more
              <i className="fa-light fa-arrow-up-right"></i>
            </a>
          </div>

          <div className="mechanism narrow-section" ref={uprRef}>
            <header>
              <img src={uprImg} alt="Universal Periodic Review" />
            </header>

            <h2>Universal Periodic Review</h2>

            <p>The Universal Periodic Review (UPR) is a unique process which involves a review of the human rights records of all UN Member States.</p>

            <NavLink className="blue-button" to="/mechanisms/upr">
              Read more
              <i className="fa-light fa-arrow-up-right"></i>
            </NavLink>
          </div>

          <div className="mechanism narrow-section" ref={specialProceduresRef}>
            <header>
              <img src={specialProceduresImg} alt="What are human rights mechanisms?" />
            </header>

            <h2>Special Procedures</h2>

            <p>The Special Procedures are independent human rights experts who report and advise on country situations or thematic issues in all parts of the world. These experts undertake country visits and present a report to the Human Rights Council containing recommendations made to the State following their visit.</p>

            <NavLink className="blue-button" to="/mechanisms/special-procedures">
              Read more
              <i className="fa-light fa-arrow-up-right"></i>
            </NavLink>
          </div>

          <div className="mechanism narrow-section" ref={treatyBodiesRef}>
            <header>
              <img src={treatyBodiesImg} alt="What are human rights mechanisms?" />
            </header>

            <h2>Treaty Bodies</h2>

            <p>By ratifying international human rights treaties, <strong>Utopia</strong> has assumed different obligations and duties under international law to respect, to protect and to fulfil human rights. </p>

            <p>To ensure States are taking steps to guarantee that everyone enjoys the rights set out in these treaties, treaty bodies regularly consider reports submitted by State Parties. <a href="#" target="_blank">The Treaty Bodies</a> are committees of independent experts that review the human rights situation in the State Party, monitor the implementation of the human rights treaties and issue concerns and recommendations on further action to be taken by the State party. In total, there are ten human rights Treaty Bodies monitoring the implementation of nine core human rights treaties.</p>

            <p>Below, you will find the international human rights treaties to which <strong>Utopia</strong> is a State Party and information on the latest examinations conducted to <strong>Utopia</strong> by UN treaty bodies:</p>

            <div className="treaties">
              <div className="treaty">
                <div className="treaty-img">
                  <img src={iccprImg} alt="Human Rights Committee (ICCPR)" />
                </div>

                <div className="treaty-content">
                  <h3>Human Rights Committee (ICCPR)</h3>
  
                  <p>Monitors implementation of the International Covenant on Civil and Political Rights (23 March 1976) and its optional protocols.</p>
  
                  <NavLink className="blue-button" to="/mechanisms/human-rights-committee">
                    Read more
                    <i className="fa-light fa-arrow-up-right"></i>
                  </NavLink>
                </div>
              </div>

              <div className="treaty">
                <div className="treaty-img">
                  <img src={cescrImg} alt="Committee on Economic, Social and Cultural Rights (CESCR)" />
                </div>
                
                <div className="treaty-content">
                  <h3>Committee on Economic, Social and Cultural Rights (CESCR)</h3>
  
                  <p>Monitors implementation of the International Covenant on Economic, Social and Cultural Rights (3 January 1976).</p>
  
                  <a className="blue-button">
                    Read more
                    <i className="fa-light fa-arrow-up-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default Mechanisms;