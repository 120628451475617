// react imports
import React, { useState } from "react";
import { Link } from "react-router-dom";

// prime react imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

// styles
import "./Home.scss"

// images
import homeHero from "../../imgs/home/home-hero.jpg";
import homeEvents from "../../imgs/home/home-upcoming-events.jpg";
import homeMechanismsCardBC from "../../imgs/home/home-card-human-rights-mechanisms.jpg";
import homeRecommendationsCardBC from "../../imgs/home/home-card-recommendations.jpg";
import homeFollowUpCardBC from "../../imgs/home/home-card-follow-up-actions.jpg";

// sample data
import { EVENTS } from "../../apis/sampleData";

const Home = (props) => {
  // states
  const [searchText, setSearchText] = useState('');
  const [searchHasFocus, setSearchHasFocus] = useState(false);

  // helper functions
  const eventFilterFunction = (e) => {
    return e.event.toLocaleLowerCase().indexOf(searchText) !== -1 || 
          e.type.toLocaleLowerCase().indexOf(searchText) !== -1 ||
          e.mechanism.toLocaleLowerCase().indexOf(searchText) !== -1;
  };

  // template functions
  const getDateCell = rowData => {
    let dayPart = rowData.date.getDay() > 9 ? rowData.date.getDay() : `0${rowData.date.getDay()}`;
    let monthPart = rowData.date.getMonth() > 9 ? rowData.date.getMonth() : `0${rowData.date.getMonth()}`;
    let yearPart = rowData.date.getFullYear();

    return `${dayPart}.${monthPart}.${rowData.date.getFullYear()}`;
  };

  // search class - for animation
  let searchWrapperClasses = ['search-wrapper'];
  if (searchHasFocus || searchText.length > 0) {
    searchWrapperClasses.push('full-width');
  }


  return (
    <div className="page-wrapper home">
      <div className="container">
        <div className="home-hero">
          <div className="home-hero__left">
            <h1>Welcome to the Utopia Recommendations Tracker!</h1>
            <p>Dolor amet sed sit non mauris mauris tristique faucibus. Aliquet vel pellentesque neque volutpat enim. Augue semper amet consequat venenatis nec ut quam feugiat. Nunc arcu.</p>

            <button className="blue-button">
              Learn more
              <i className="fa-light fa-arrow-up-right"></i>
            </button>
          </div>
          <div className="home-hero__right">
            <img src={homeHero} alt="Home Hero" />
          </div>
        </div>

        <div className="home-events" style={{backgroundImage: `url(${homeEvents})`}}>
          <h2>Upcoming events</h2>

          <p>Porta egestas bibendum iaculis tincidunt lacus. Amet pharetra tempor eu id neque libero ullamcorper ultrices et. Velit risus ornare quis varius malesuada scelerisque.  Amet pharetra tempor eu id neque libero.</p>

          <div className="events-list">
            <h3>Latest events</h3>

            <div className={searchWrapperClasses.join(' ')}>
              <i className="fa-light fa-magnifying-glass"></i>
              <input 
                id="search" 
                placeholder="Search" 
                value={searchText} 
                onChange={(e) => setSearchText(e.target.value)}
                onFocus={() => setSearchHasFocus(true)}
                onBlur={() => setSearchHasFocus(false)}
              />
            </div>

            <DataTable value={EVENTS.filter(eventFilterFunction)}>
              <Column className="event" header="Event" field="event" sortable></Column>
              <Column className="type" header="Type" field="type" sortable></Column>
              <Column className="mechanism" header="Mechanism" field="mechanism" sortable></Column>
              <Column className="date" header="Date" field="date" body={getDateCell}  sortable></Column>
            </DataTable>
          </div>

          <button className="blue-button">
            Show all
            <i className="fa-light fa-arrow-up-right"></i>
          </button>
        </div>

        <div className="home-human-rights narrow-section">
          <h2>Utopia and the international human rights system</h2>

          <p>Leo penatibus vel venenatis, vestibulum aliquet velit magna amet, ut. Egestas sagittis risus faucibus nibh at eu. Viverra pretium in in lobortis.</p>

          <div className="home-cards">
            <div className="card" style={{backgroundImage: `url(${homeMechanismsCardBC})`}}>
              <h3>Human rights mechanisms</h3>

              <div className="card-cover">
                <p>Explore 928 recommendations issued by 48 mechanisms</p>
  
                <div className="numbers-list">
                  <div className="item">
                    <span>240</span> from Lorem ipsum
                  </div>
  
                  <div className="item">
                    <span>14</span> from Dolor sit
                  </div>
  
                  <div className="item">
                    <span>25</span> lorem ipsum
                  </div>
                </div>

                <Link className="chevron-link" to="../mechanisms" preventScrollReset={false}>
                  <i className="fa-light fa-chevron-right"></i>
                </Link>
              </div>
            </div>

            <div className="card" style={{backgroundImage: `url(${homeFollowUpCardBC})`}}>
              <h3>Recommendations</h3>
              
              <div className="card-cover">
                <p>Explore 928 recommendations issued by 48 mechanisms</p>
  
                <div className="numbers-list">
                  <div className="item">
                    <span>312</span> from Treaty bodies
                  </div>
  
                  <div className="item">
                    <span>260</span> from UPR
                  </div>
  
                  <div className="item">
                    <span>25</span> from Special procedures
                  </div>
                </div>

                <Link className="chevron-link" to="../recommendations">
                  <i className="fa-light fa-chevron-right"></i>
                </Link>
              </div>
            </div>

            <div className="card" style={{backgroundImage: `url(${homeRecommendationsCardBC})`}}>
              <h3>Follow-up actions</h3>
              
              <div className="card-cover">
                <p>Explore 928 recommendations issued by 48 mechanisms</p>
  
                <div className="numbers-list">
                  <div className="item">
                    <span>240</span> from Lorem ipsum
                  </div>
  
                  <div className="item">
                    <span>14</span> in progress
                  </div>
  
                  <div className="item">
                    <span>25</span> not started
                  </div>
                </div>

                <Link className="chevron-link" to="../follow-up-activities">
                  <i className="fa-light fa-chevron-right"></i>
                </Link>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
  );
};

export default Home;