// react imports
import React, { useState } from "react";

// prime imports
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

// custom components
import AttachmentDialog from "../../../common/dialogs/attachment-dialog/AttachmentDialog";


// sample
import { PROJECTS, RECOMMENDATIONS } from "../../../../apis/sampleData";

// styles
import "./RecommendationSidepanel.scss"

/**
 * 
 * @param recommendation
 * @returns 
 */
const RecommendationSidepanel = (props) => {
  // consts
  const projects = props.recommendation.projectIds.map(projectId => PROJECTS.find(p => p.id === projectId));
  const activities = props.recommendation.activityIds.map(activityId => RECOMMENDATIONS.find(r => r.id === activityId));

  return (
    <div className="single-element-sidepanel">
      <div className="single-element-sidepanel-list projects">
        <title>In Projects</title>
        {projects.map(project => (
          <div className="sidepanel-list-item project-name-item" key={project.id}>
            <div className="item-content">
              {project.name}
            </div>
            {/* <div className="remove-button" onClick={() => console.log(project.id)}>
              <i className="fa-regular fa-xmark"></i>
            </div> */}
          </div>
        ))}
      </div>

      <div className="single-element-sidepanel-list activities">
        <title>Activities</title>
        {activities.map(activity => (
          <div className="sidepanel-list-item" key={activity.id}>
            <div className="item-content">
              <i className="fa-regular fa-arrow-up-right-from-square"></i>
              <span>{activity.name}</span>
            </div>
            {/* <div className="remove-button" onClick={() => console.log(activity.id)}>
              <i className="fa-regular fa-xmark"></i>
            </div> */}
          </div>
        ))}

      </div>
    </div>
  );
};

export default RecommendationSidepanel;