// react imports
import { useState } from "react";
import { useNavigate } from "react-router";

// 3rd party components
import { Collapse } from "react-collapse";

// primereact imports
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

// custom components
import FilterSelect from "../../common/filter-select/FilterSelect";
import CollapseTrigger from '../../common/collapse-trigger/CollapseTrigger';

// sample data
import { MECHANISMS_DATES_RECOMMENDATIONS } from "../../../apis/sampleData";

// styles
import "./RecommendationList.scss";


/**
 * Props properties:
 * @param recommendationListHeight: number - (if not set, the list will not have a fixed height)
 * @param recommendationClickable: boolean
 * @param multipleAddingLinkText: string (placeholder for number of selected: #NUMBER)
 * @param isRecommendationsHighlightable: booleam
 */
const RecommendationList = props => {
  const navigate = useNavigate();

  // consts
  const recommendationSortOptions = ["RECOMMENDING BODY A-Z", "RECOMMENDING BODY Z-A"];

  // states
  const [recommendationSort, setRecommendationSort] = useState(recommendationSortOptions[0]);
  const [filteredMechanisms, setFilteredMechanisms] = useState(MECHANISMS_DATES_RECOMMENDATIONS);
  const [selectedRecommendations, setSelectedRecommendation] = useState([]);

  // helper function
  const changeDateOpened = ({ mechanismId, dateString }) => {
    const newFilteredMechanism = [...filteredMechanisms];

    // get the mechanism object
    const mechanism = newFilteredMechanism.find(m => m.id === mechanismId);

    // get the date object
    const date = mechanism.dates.find(d => d.dateString === dateString);

    // change the opened property
    date.opened = !date.opened;

    setFilteredMechanisms(newFilteredMechanism);
  };
  const getPropertyClickHandler = propertyName => ({ mechanismId, dateString, recommendationId }) => {
    const newFilteredMechanism = [...filteredMechanisms];

    // get the mechanism object
    const mechanism = newFilteredMechanism.find(m => m.id === mechanismId);

    // get the date object
    const date = mechanism.dates.find(d => d.dateString === dateString);

    // get recommendation
    const recommendation = date.recommendations.find(r => r.id === recommendationId);

    // change open state
    recommendation[propertyName] = !recommendation[propertyName];

    setFilteredMechanisms(newFilteredMechanism);
  };
  const clickOnLinkRecommendationHandler = (mechanismId, dateString, recommendationId) => {
    const newFilteredMechanism = [...filteredMechanisms];

    // get the mechanism object
    const mechanism = newFilteredMechanism.find(m => m.id === mechanismId);

    // get the date object
    const date = mechanism.dates.find(d => d.dateString === dateString);

    // get recommendation
    const recommendation = date.recommendations.find(r => r.id === recommendationId);

    // change open state
    recommendation.linked = !recommendation.linked;

    setFilteredMechanisms(newFilteredMechanism);
  };
  const clickOnRecommendationCB = (mechanismId, dateString, recommendationId) => {
    const newFilteredMechanism = [...filteredMechanisms];

    // get the mechanism object
    const mechanism = newFilteredMechanism.find(m => m.id === mechanismId);

    // get the date object
    const date = mechanism.dates.find(d => d.dateString === dateString);

    // get recommendation
    const recommendation = date.recommendations.find(r => r.id === recommendationId);

    // change open state
    recommendation.selected = !recommendation.selected;

    setFilteredMechanisms(newFilteredMechanism);
  };
  const isRecommendationSelected = (mechanismId, dateString, recommendationId) => {
    const newFilteredMechanism = [...filteredMechanisms];

    // get the mechanism object
    const mechanism = newFilteredMechanism.find(m => m.id === mechanismId);

    // get the date object
    const date = mechanism.dates.find(d => d.dateString === dateString);

    // get recommendation
    const recommendation = date.recommendations.find(r => r.id === recommendationId);

    return recommendation.selected;
  };
  const getClickOnRecommendationContent = (mechanismId, dateString, recommendationId) => () => {
    if (props.recommendationClickable) {
      navigate(`/recommendations/${mechanismId}/${dateString.replaceAll('/', '-')}/${recommendationId}`);
    }
  };
  const clickOnRecommendationStarHandler = (mechanismId, dateString, recommendationId) => {
    const newFilteredMechanism = [...filteredMechanisms];

    // get the mechanism object
    const mechanism = newFilteredMechanism.find(m => m.id === mechanismId);

    // get the date object
    const date = mechanism.dates.find(d => d.dateString === dateString);

    // get recommendation
    const recommendation = date.recommendations.find(r => r.id === recommendationId);

    // change open state
    recommendation.highlighted = !recommendation.highlighted;

    setFilteredMechanisms(newFilteredMechanism);
  };

  // templates
  const listJSX = (
    <div className="p-dataview-content">
      {filteredMechanisms.map(mechanism => (
        <div key={mechanism.id} id={mechanism.id} className="mechanism">
          <div className="mechanism-title">
            {mechanism.title}
          </div>
          {mechanism.dates.map(date => (
            <div className="mechanism-date" key={mechanism.id + "-" + date.dateString}>
              <CollapseTrigger
                className="date-header"
                opened={date.opened}
                triggerPropertyObject={{ mechanismId: mechanism.id, dateString: date.dateString }}
                stateChanged={changeDateOpened}
              >
                <a href="https://google.com" className="date-title" target="_blank" rel="noreferrer">
                  {date.dateString}
                  <i className="fa-regular fa-arrow-up-right-from-square"></i>
                </a>

                <div className="recommendation-number">
                  {date.recommendations.length}
                </div>
              </CollapseTrigger>
              <Collapse isOpened={date.opened} theme={{ collapse: 'date-content' }}>
                {date.recommendations.map(recommendation => (
                  <div key={recommendation.id} className="nrtd-list-item recommendation">
                    {props.isMultipleAddingAvailable && (
                      <div className="nrtd-list-item--selection">
                        <Checkbox
                          onChange={() => clickOnRecommendationCB(mechanism.id, date.dateString, recommendation.id)}
                          checked={isRecommendationSelected(mechanism.id, date.dateString, recommendation.id)}>

                        </Checkbox>
                      </div>
                    )}
                    <div className={`nrtd-list-item--content`}>
                      <title
                        className={`${props.recommendationClickable ? 'clickable' : ''}`}
                        onClick={getClickOnRecommendationContent(mechanism.id, date.dateString, recommendation.id)}
                      >
                        {recommendation.title}
                      </title>

                      <div className="nrtd-list-item--metadata">
                        <CollapseTrigger
                          arrowOnRight={true}
                          opened={recommendation.metadataOpened}
                          stateChanged={getPropertyClickHandler("metadataOpened")}
                          triggerPropertyObject={{ mechanismId: mechanism.id, dateString: date.dateString, recommendationId: recommendation.id }}
                        >
                          <title className="metadata-column">Affected Persons</title>

                          <title className="metadata-column">Themes</title>

                          <title className="metadata-column">Sustainable Development Goals</title>
                        </CollapseTrigger>
                        <Collapse isOpened={recommendation.metadataOpened} theme={{ collapse: 'nrtd-list-item--metadata-values list' }}>
                          <div className="metadata-column">
                            <ul>
                              <li>lorem ipsum</li>
                              <li>lorem ipsum</li>
                              <li>lorem ipsum</li>
                            </ul>
                          </div>

                          <div className="metadata-column">
                            <ul>
                              <li>lorem ipsum</li>
                              <li>lorem ipsum</li>
                              <li>lorem ipsum</li>
                            </ul>
                          </div>

                          <div className="metadata-column">
                            <ul>
                              <li>lorem ipsum</li>
                              <li>lorem ipsum</li>
                              <li>lorem ipsum</li>
                            </ul>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                    {/* <div className="nrtd-list-item--links">
                      {props.isLinkable && (
                        <Button
                          icon="lafa-solid fa-link"
                          label={recommendation.linked ? "Unlink recommendation" : "Link recommendation"}
                          className="p-button-text link-recommendation"
                          onClick={() => clickOnLinkRecommendationHandler(mechanism.id, date.dateString, recommendation.id)}
                        />
                      )}
                    </div> */}
                    {props.isRecommendationsHighlightable && (
                      <div className="nrtd-list-item--star">
                        <Button
                          icon={`${recommendation.highlighted ? 'fa-solid fa-star' : 'fa-regular fa-star'}`}
                          className={`p-button-text p-button-secondary star`}
                          onClick={() => clickOnRecommendationStarHandler(mechanism.id, date.dateString, recommendation.id)}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </Collapse>

            </div>
          ))}
        </div>
      ))}
    </div>
  );

  // calculations
  let numberOfSelectedRecommendations = 0;
  filteredMechanisms.forEach((mechanism, mechanismId) => {
    mechanism.dates.forEach((date, dateId) => {
      numberOfSelectedRecommendations += date.recommendations.filter(r => r.selected).length;
    });
  });



  return (
    <div className="nrtd-list project-recommendation-list">
      <div className="nrtd-list-header">
        <div className="dataview-number-of-items">
          32 recommendations from 4 mechanisms
        </div>

        <div className="dataview-list-sort-buttons">
          {props.isMultipleAddingAvailable && numberOfSelectedRecommendations > 0 && (
            <Button
              icon="fa-regular fa-circle-plus"
              label={props.multipleAddingLinkText.replace('#NUMBERS', numberOfSelectedRecommendations)}
              className="p-button-text"
            />
          )}

          <FilterSelect
            title="Sort"
            iconClass="fa-solid fa-arrow-down-wide-short"
            options={recommendationSortOptions}
            value={recommendationSort}
            onSelectValue={setRecommendationSort}
          />
        </div>

      </div>

      {props.recommendationListHeight && (
        <ScrollPanel
          style={{ width: '100%', height: `${props.recommendationListHeight}px` }}
        >
          {listJSX}
        </ScrollPanel>
      )}

      {!props.recommendationListHeight && (listJSX)}
    </div>
  );
};

export default RecommendationList;
