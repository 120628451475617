// react
import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";

// primereact components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toolbar } from "primereact/toolbar";
import { Avatar } from 'primereact/avatar';

// style
import "./Header.scss";

/**
 * 
 * @param
 * @returns 
 */
const Header = props => {
  // states
  

  // events

  // helper jsx
  const topBarleftContents = (
    <NavLink to="/" className="logo">
      <i className="fa-duotone fa-file-signature"></i>
      Utopia
    </NavLink>
  );

  const topBarRightContents = (
    <>
      <NavLink to="../mechanisms">
        Human rights mechanisms
      </NavLink>

      <NavLink to="../recommendations">
        Recommendations
      </NavLink>

      <NavLink to="../follow-up-activities">
        Follow-up activities
      </NavLink>

      <NavLink to="../statistics">
        Statistics
      </NavLink>

      <NavLink to="../about">
        About
      </NavLink>
    </>
  );

  return (
    <div className="container">
      <Toolbar left={topBarleftContents} right={topBarRightContents} className="main-header" />
    </div>
  );
};

export default Header;