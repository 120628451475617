// react imports
import React, { useState } from "react";

// prime imports
import { Button } from "primereact/button";

// custom components
import AttachmentDialog from "../../../common/dialogs/attachment-dialog/AttachmentDialog";


// styles
import "./ProjectSidepanel.scss"

/**
 * 
 * @param project
 * @returns 
 */
const ProjectSidepanel = (props) => {
  // states
  const [attachmentDialogVisible, setAttachmentDialogVisible] = useState(false);
  const [addActionPlanDialogVisible, setAddActionPlanDialogVisible] = useState(false);

  return (
    <div className="single-element-sidepanel project">
      <div className="single-element-sidepanel-list activities">
        <title>Related action plans</title>
        {props.project.actionPlans.map(actionPlan => (
          <div className="sidepanel-list-item action-plan" key={actionPlan}>
            <div className="item-content">
              <i className="fa-regular fa-clipboard-check"></i>
              <span>{actionPlan}</span>
            </div>
            {/* <div className="remove-button" onClick={() => console.log(actionPlan)}>
              <i className="fa-solid fa-xmark"></i>
            </div> */}
          </div>
        ))}
      </div>

      <div className="single-element-sidepanel-list institutions">
        <title>Institutions</title>
        {props.project.institutions.map(institution => (
          <div className="sidepanel-list-item" key={institution}>
            <div className="item-content">
              <span>{institution}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="single-element-sidepanel-list attachments">
        <title>Attachments</title>
        {props.project.attachments.map(attachment => (
          <div className="sidepanel-list-item" key={attachment.name}>
            <a className="item-content" href={attachment.url} target="_blank" rel="noreferrer">
              <i className="fa-regular fa-file"></i>
              <span>{attachment.name}</span>
            </a>
            {/* <div className="remove-button" onClick={() => console.log(attachment.name)}>
              <i className="fa-solid fa-xmark"></i>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectSidepanel;