// react imports
import React from "react";
import { Link, NavLink } from "react-router-dom";

// primereact components
import { Chart } from 'primereact/chart';

// custom components
import Hero from "../../common/hero/Hero";
import StagesCircleDiagram from "../../common/stages-circle-diagram/StagesCircleDiagram";
import Bars from "../../common/bars/Bars";

// styles
// import "./HumanRightsCommittee.scss"

// images
import iccprHero from "../../../imgs/human-rights-committee/iccpr-hero.jpg";

const HumanRightsCommittee = () => {

  const chartData = {
    datasets: [
      {
        data: [300, 200],
        backgroundColor: [
          "#0A6AAE",
          "#B2CEDF"
        ]
      }
    ]
  };

  return (
    <div className="page-wrapper mechanism-page upr">
      <div className="container">
        <Hero
          title='Human Rights Committee (ICCPR)'
          backgroundImage={iccprHero}
          displayBackLink={true} 
        />

        <div className="mechanism-columns">
          <div className="mechanism-content">
            <p>
              The treaty body in charge of monitoring the implementation of the International Covenant on Civil and Political Rights (ICCPR) in <strong>Utopia</strong> is the UN Human Rights Committee.
            </p>

            <h2>The Human Rights Committee review (Simplified Reporting Procedure)</h2>

            <h3>1. Utopia receives the list of issues prior to reporting (LOIPR)</h3>

            <p>By sending a list of issues prior to reporting to the State, the Committee makes specific requests for information on the human rights situation in <strong>Utopia</strong>. </p>

            <p>To better identify priority human rights issues of concern, the Committee resorts to different sources of information. This includes recommendations made by other UN human rights mechanisms and information provided by United Nations entities, national human rights institutions (NHRIs), civil society organizations (CSOs) and other stakeholders.</p>

            <p>Check the latest LOIPR sent to <strong>Utopia</strong> from the Committee: <a href="#">here</a></p>

            <h3>2. Utopia prepares their replies to the LOIPR in the form of a State party report</h3>

            <p>
              The replies of the State party to the list of issues prior to reporting constitute the State report.
              Check <strong>Utopia's</strong> report <a href="#">here</a> 
            </p>

            <p>(If not available indicate Due date of report and add Check upcoming events organized by UTOPIA to review the draft report)</p>

            <h3>3. The Human Rights Committee examines Utopia during their session </h3>            

            <p>The Committee considers the State report through a constructive/interactive dialogue with <strong>Utopia</strong> during the session of the Committee.</p>

            <h3>4. The Committee issues its concerns and recommendations to UTOPIA in the form of concluding observations</h3>

            <p>The recommendations contained in the concluding observations provide guidance for <strong>Utopia</strong> to progress on the implementation of their human rights obligations under the International Covenant on Civil and Political Rights (ICCPR). </p>

            <p>Explore the <a href="#">60 recommendations</a> issued by the Human Rights Committee in its last concluding observations or download their latest <a href="#">concluding observations</a>.</p>

            <h3>5. UTOPIA follows-up and implements recommendations received</h3>

            <p><strong>Utopia</strong> is using the <strong>Utopia's</strong> Human Rights tracker to prioritize recommendations received by the Committee and other human rights mechanisms, plan follow-up activities, and coordinate follow-up action across line ministries.</p>

            <p>Explore the implementation status of the <a href="#">132 follow-up activities</a></p>
          </div>
  
          <div className="mechanism-side-panel">
            <div className="side-panel-info">
              <label>Ratification date</label>
              <div className="value">15/09/2004</div>
            </div>
            <div className="side-panel-info">
              <label>Utopia's next review before the UN Human Rights Committee: </label>
              <div className="value">2019</div>
            </div>
            <div className="side-panel-info">
              <label>Reporting procedure</label>
              <div className="value">Standard</div>
            </div>
            <div className="side-panel-info">
              <label>Last review</label>
              <div className="value">02/04/2023</div>
            </div>

            {/* <Bars bars={[
              [{ percentage: 60, color: '#0A6AAE' }, { percentage: 40, color: '#FFAB2B' }],
              [{ percentage: 20, color: 'purple' }, { percentage: 45, color: 'red' }, { percentage: 30, color: 'yellow' }, { percentage: 5, color: 'green' }],
              [{ percentage: 60, color: 'orange' }, { percentage: 40, color: 'yellow' }, { percentage: 40, color: 'red' }]
            ]} /> */}

            <Bars bars={[ [{ percentage: 70, color: '#0A6AAE' }, { percentage: 30, color: '#FFAB2B' }] ]} />
  
            <div className="side-panel-info multiple">
              <div className="col">
                <label>Issued by the Committee</label>
                <div className="value smaller">
                  60 Recommendations
                </div>
              </div>
              <div className="col">
                <label className="orange">Have assigned to them</label>
                <div className="value smaller">20 follow-up activities</div>
              </div>
            </div>
          </div>
        </div>

        <StagesCircleDiagram />
      </div>
    </div>
  );
};

export default HumanRightsCommittee;

