// react imports
import React, { useState } from "react";

// prime react components
import { Button } from "primereact/button";

// custom components
import Hero from "../common/hero/Hero";
import StatisticsChart from "./statistics-chart/StatisticsChart";
import StatisticsPieChart from "./statistics-pie-chart/StatisticsPieChart";
import StatisticsVerticalBarChart from "./statistics-vertical-barchart/StatisticsVerticalBarChart";

// consts
import { COLORS } from "../../apis/constants";

// imgs
import heroImg from "../../imgs/human-rights-mechanisms/human-rights-hero.jpg";

// styles
import "./Statistics.scss"


const Statistics = (props) => {
  const data = [
    {
      label: 'Lorem ipsum dolor sit amet eripteum sit dolor',
      types: [
        { label: 'UPR', value: 6, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 4, backgroundColor: COLORS.lightBlue },
        { label: 'Treaty bodies', value: 2, backgroundColor: COLORS.primaryBlue }
      ]
    },
    {
      label: 'Lorem ipsum sit amet',
      types: [
        { label: 'UPR', value: 3, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 3, backgroundColor: COLORS.lightBlue },
        { label: 'Treaty bodies', value: 4, backgroundColor: COLORS.primaryBlue }
      ]
    },
    {
      label: 'Lorem ipsum',
      types: [
        { label: 'UPR', value: 6, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 12, backgroundColor: COLORS.lightBlue },
        { label: 'Treaty bodies', value: 2, backgroundColor: COLORS.primaryBlue }
      ]
    },
    {
      label: 'Lorem ipsum amet',
      types: [
        { label: 'UPR', value: 1, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 2, backgroundColor: COLORS.lightBlue },
        { label: 'Treaty bodies', value: 4, backgroundColor: COLORS.primaryBlue }
      ]
    },
    {
      label: 'Lorem ipsum',
      types: [
        { label: 'UPR', value: 5, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 3, backgroundColor: COLORS.lightBlue },
        { label: 'Treaty bodies', value: 6, backgroundColor: COLORS.primaryBlue }
      ]
    },
    {
      label: 'Lorem ipsum dolor sit',
      types: [
        { label: 'UPR', value: 8, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 1, backgroundColor: COLORS.lightBlue },
        { label: 'Treaty bodies', value: 1.5, backgroundColor: COLORS.primaryBlue }
      ]
    },
    {
      label: 'Lorem ipsum',
      types: [
        { label: 'UPR', value: 2, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 8, backgroundColor: COLORS.lightBlue },
        { label: 'Treaty bodies', value: 2.5, backgroundColor: COLORS.primaryBlue }
      ]
    },
    {
      label: 'Lorem ipsum',
      types: [
        { label: 'UPR', value: 11, backgroundColor: '#DADDE0' },
        { label: 'Special Procedures', value: 0, backgroundColor: COLORS.lightBlue },
        { label: 'Treaty bodies', value: 2, backgroundColor: COLORS.primaryBlue }
      ]
    }
  ];

  const [activeTab, setActiveTab] = useState('vertical-barchart');

  return (
    <div className="page-wrapper statistics-page">
      <div className="container">
        <Hero
          title="Statistics"
          backgroundImage={heroImg}
        />

        <div className="narrow-section statistics-page-content">
          <Button
            icon="las la-external-link-alt"
            label="Export"
            className="p-button-text p-button-secondary export"
            onClick={() => console.log('Export')}
          />
  
          <h2>
            Lorem ipsum dolor sit amet
          </h2>
  
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam velit magna, molestie eget lobortis et, sollicitudin non lacus. Donec accumsan arcu eu erat congue pretium pretium sed tellus.
          </p>
  
          <div className="sp--charts">
            <div className="sp--charts--tablist">
              <div className={activeTab === 'sdgs' ? 'active' : ''} onClick={() => setActiveTab('sdgs')}>
                <title>SDGs</title>
                <div>
                  <i className="las la-list-alt"></i>
                  23/24
                </div>
                <div className="tab--progress-bar">
                  <div style={{ width: (23 / 24) * 100 + '%' }}></div>
                </div>
              </div>
              <div className={activeTab === 'themes' ? 'active' : ''} onClick={() => setActiveTab('themes')}>
                <title>Themes</title>
                <div>
                  <i className="las la-list-alt"></i>
                  14/35
                </div>
                <div className="tab--progress-bar">
                  <div style={{ width: (14 / 35) * 100 + '%' }}></div>
                </div>
              </div>
              <div className={activeTab === 'affected-persons' ? 'active' : ''} onClick={() => setActiveTab('affected-persons')}>
                <title>Affected persons</title>
                <div>
                  <i className="las la-list-alt"></i>
                  4/12
                </div>
                <div className="tab--progress-bar">
                  <div style={{ width: (4 / 12) * 100 + '%' }}></div>
                </div>
              </div>
              <div className={activeTab === 'vertical-barchart' ? 'active' : ''} onClick={() => setActiveTab('vertical-barchart')}>
                <title>Vertical Barchart</title>
                <div>
                  <i className="las la-list-alt"></i>
                  40/70
                </div>
                <div className="tab--progress-bar">
                  <div style={{ width: (40 / 70) * 100 + '%' }}></div>
                </div>
              </div>
            </div>
            <div className="sp--charts--tabcontent">
              {(activeTab === 'sdgs') && <StatisticsChart title='SDGs' data={data} />}
              {(activeTab === 'themes') && <StatisticsChart title='Themes' data={data} />}
              {(activeTab === 'affected-persons') && <StatisticsPieChart title='Affected Persons' data={data} />}
              {(activeTab === 'vertical-barchart') && <StatisticsVerticalBarChart title='Vertical BarChart' data={data} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;