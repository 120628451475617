// react imports
import React, { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

// prime imports
import { Button } from "primereact/button";

// custom components
import ActionMenu from "../../common/action-menu/ActionMenu";
import RecommendationDetailsContent from "./recommendation-details-content/RecommendationDetailsContent";
import RecommendationSidepanel from "./recommendation-sidepanel/RecommendationSidepanel";
import Updates from "../../common/updates/Updates";
import Hero from "../../common/hero/Hero";

// sample
import { ACTIVITIES, AFFECTED_PERSONS, THEMES, MECHANISMS_DATES_RECOMMENDATIONS, UPDATES } from "../../../apis/sampleData";

// images
import heroImg from "../../../imgs/human-rights-mechanisms/human-rights-hero.jpg";

// styles
import "./RecommendationPage.scss"

const RecommendationPage = () => {
  // react helpers
  const params = useParams();
  const navigate = useNavigate();

  // content
  const mechanism = useMemo(() => MECHANISMS_DATES_RECOMMENDATIONS.find(m => m.id === parseInt(params.mechanismId)), [params.mechanismId]);
  const dateObject = useMemo(() => mechanism.dates.find(d => d.dateString === params.dateString.replaceAll('-', '/')), [params.dateString, mechanism.dates]);
  const recommendation = useMemo(() => dateObject.recommendations.find(r => r.id === parseInt(params.recommendationId)), [params.recommendationId, dateObject.recommendations]);

  // states
  const [selectedTab, setSelectedTab] = useState('Details');


  return (
    <div className="page-wrapper recommendation">
      <div className="container">
        <Hero
          title='Recommendations'
          backgroundImage={heroImg}
        />

        <div className="narrow-section single-element recommendation">
          

          <div className="single-element-header">
            <div>
              <Button
                icon="fa-light fa-circle-chevron-left"
                className="p-button-text p-button-secondary"
                onClick={() => navigate(-1)}
              />

              <div className="single-element-tabs">
                <Button
                  label="Details"
                  className={`p-button-text ${selectedTab === 'Details' ? '' : 'p-button-secondary'}`}
                  onClick={() => setSelectedTab('Details')}
                />

                <Button
                  label="Updates"
                  className={`p-button-text ${selectedTab === 'Updates' ? '' : 'p-button-secondary'}`}
                  onClick={() => setSelectedTab('Updates')}
                />
              </div>
            </div>

            <div>
              <Button
                icon="fa-regular fa-arrow-up-right-from-square"
                label="Export"
                className="p-button-text p-button-secondary"
                onClick={() => console.log("export")}
              />

              {/* <ActionMenu
                actions={[
                  { name: 'Recommendation permissions' },
                  { name: 'Delete Recommendation' }
                ]}
                actionSelected={(selectedActionName => console.log(selectedActionName))}
              /> */}
            </div>
          </div>

          <div className="single-element-content">
            {selectedTab === "Details" &&
              <RecommendationDetailsContent
                mechanismTitle={mechanism.title}
                dateString={dateObject.dateString}
                recommendation={recommendation}
              />
            }

            {selectedTab === "Updates" && <Updates updateItems={UPDATES} />}

            <RecommendationSidepanel recommendation={recommendation} />
          </div>


        </div>
      </div>
    </div>
  );
};

export default RecommendationPage;