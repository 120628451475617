// react imports
import { React, useState } from 'react';

// helper api funcs
import { truncate } from '../../../apis/helperFunc';

// primereact imports
import { Chart } from "primereact/chart";

// consts
import FilterSelect from '../../common/filter-select/FilterSelect';

// styles
import './StatisticsChart.scss';


// helper funcs
const getDataSortCompareFunc = sorting => (data1, data2) => {
  if (sorting === 'A-Z') {
    return (data1.label > data2.label) ? 1 : -1;
  } else {
    return (data1.label < data2.label) ? 1 : -1;
  }
};
const dataValidation = data => {
  if (!Array.isArray(data)) {
    throw new Error('props.data is not an array');
  }

  if (!data.length === 0) {
    throw new Error('props.data is empty');
  }

  let numberOfTypes = -1;
  data.forEach((d, i) => {
    if (!Array.isArray(d.types)) {
      throw new Error(`props.data[${i}].types is not an array`);
    }

    if (Array.isArray(d.types.length === 0)) {
      throw new Error(`props.data[${i}].types is empty`);
    }


    if (numberOfTypes === -1) {
      numberOfTypes = d.length;
    }

    if (numberOfTypes !== d.length) {
      throw new Error('props.data - not every types has the same length');
    }
  });
};
const breakLabelsIntoArrays = data => {
  data.forEach(d => {
    d.label = truncate(d.label, 75);

    if (d.label.length > 26) {
      let labelWords = d.label.split(' ');
      d.label = [];
      let labelElem = '';

      labelWords.forEach(word => {
        if ((labelElem + ' ' + word).length > 26) {
          d.label.push(labelElem);

          labelElem = word;
        } else {
          labelElem = labelElem === '' ? word : (labelElem + ' ' + word);
        }
      });

      d.label.push(labelElem);
    }
  });
}


/**
 * Props properties:
 * @param props.title: string
 * @param props.data: array of {
 *  label: string,
 *  types: array of { 
 *    label: string,
 *    value: number, 
 *    backgroundColor: string 
 *  }
 * }
 */
const StatisticsChart = props => {
  const sortingOptions = ['A-Z', 'Z-A'];
  const [sorting, setSorting] = useState(sortingOptions[0]);

  dataValidation(props.data);

  const sortedData = props.data.sort(getDataSortCompareFunc(sorting));

  breakLabelsIntoArrays(sortedData);

  const sortedLabels = sortedData.map(d => d.label);
  const sortedDataSets = sortedData[0].types.map(type => ({
    type: 'bar',
    label: type.label,
    backgroundColor: type.backgroundColor,
    data: [],
    barThickness: 16
  }));

  sortedData.forEach(d => {
    d.types.forEach((type, index) => {
      sortedDataSets[index].data.push(type.value);
    });
  });

  const chartData = {
    labels: sortedLabels,
    datasets: sortedDataSets
  };

  let chartOptions = {
    indexAxis: 'y',
    plugins: {
      legend: {
        labels: {
          color: '#495057',
          padding: 20
        },
        position: 'bottom',
        align: 'end',
        onClick: null
      },
      tooltip: {
        yAlign: 'bottom',
        xAlign: 'center',
        position: 'nearest',
        callbacks: {
          label: function (context) {
            return context.dataset.label.toUpperCase() + ': ' + context.raw;
          },
          title: () => ''
        },
        bodyColor: '#E8ECEF',
        bodyFont: {
          family: 'Satoshi',
          size: 12,
          weight: 500
        },
        displayColors: false
      }
    },
    scales: {
      x: {
        stacked: true,
        position: 'top',
        ticks: {
          color: '#495057',
          callback: function (val, index) {
            // Hide the label of every 2nd dataset
            return index % 2 === 0 ? this.getLabelForValue(val) : '';
          },
        },
        grid: {
          color: function (context) {
            return context.index % 2 === 0 ? '#ebedef' : 'transparent';
          }
        }
      },
      y: {
        stacked: true,
        position: 'top',
        ticks: {
          color: '#252631',
          font: {
            size: 16,
            family: 'Satoshi'
          },
          crossAlign: 'far'
        },
        grid: {
          color: '#ebedef'
        }
      }
    }
  };

  return (
    <div className="statistics-chart">
      <div className="statistics-chart--title-row">
        <div className="statistics-chart--title"> {truncate(props.title, 15)} </div>
        <FilterSelect
          title="SORT"
          iconClass="las la-filter"
          options={sortingOptions}
          value={sorting}
          onSelectValue={selectedSorting => setSorting(selectedSorting)}
        />
      </div>
      <Chart
        type="bar"
        data={chartData}
        options={chartOptions}
        height={`${27 * sortedLabels.length}w`}
      />
    </div>
  );
};

export default StatisticsChart;