// react imports
import React, { useState, useRef } from "react";

// 3rd party components
import { Collapse } from "react-collapse";

// styles
import "./StagesCircleDiagram.scss"

const StagesCircleDiagram = (props) => {
  // states
  const [activeStage, setActiveStage] = useState(0);
  const [opened, setOpened] = useState(false);
  const [stageHovers, setStageHovers] = useState([
    {
      onBackground: false,
      onCircle: false,
      onNumber: false,
    },
    {
      onBackground: false,
      onCircle: false,
      onNumber: false,
    },
    {
      onBackground: false,
      onCircle: false,
      onNumber: false,
    },
    {
      onBackground: false,
      onCircle: false,
      onNumber: false,
    },
    {
      onBackground: false,
      onCircle: false,
      onNumber: false,
    },
    {
      onBackground: false,
      onCircle: false,
      onNumber: false,
    },
  ]);

  // refs
  const stageRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  

  // event functions
  const getClickOnStageFunc = number => () => {
    setActiveStage(number);
    if (opened) {
      setOpened(false);
    }
  };
  const getSetHoverStageFunc = (number, propertyName) => () => {
    setStageHovers(currentStageHovers => {
      const newStageHovers = [...currentStageHovers];

      const stageHover = newStageHovers[number];

      stageHover[propertyName] = true;

      return newStageHovers;
    });
  };
  const getUnsetHoverStageFunc = (number, propertyName) => () => {
    setStageHovers(currentStageHovers => {
      const newStageHovers = [...currentStageHovers];

      const stageHover = newStageHovers[number];

      stageHover[propertyName] = false;

      return newStageHovers;
    });
  };

  // helper func
  const getTopOffset = () => {
    if (!stageRefs[0].current) {
      return '50px';
    }

    if (activeStage === 0) {
      return '50px';
    }

    if (activeStage === 1) {
      return '0px';
    }

    let offset = -30;

    for (let index = 1; index < stageRefs.length && index < activeStage; index++) {
       offset -= stageRefs[index].current.clientHeight;
    }
    
    return offset + 'px';
  };

  // get class functions
  const getStageClass = (number) => {
    return activeStage === number ? 'active' : '';
  };
  const getChevronClass = (number) => {
    return activeStage === number && opened ? 'opened' : '';
  };
  const getStageSVGClass = (number) => {
    let classes = [];
    
    if (stageHovers[number].onBackground || stageHovers[number].onCircle || stageHovers[number].onNumber) {
      classes.push('hover');
    }

    if (number === activeStage) {
      classes.push('active');
    }

    return classes.join(' ');
  };

  return (
    <div className="stages-circle-diagram">
      <div className="diagram-wrapper">
        <svg width="464" height="464" viewBox="0 0 464 464" fill="none" xmlns="http://www.w3.org/2000/svg">
          { /* STAGE 1 */ }
          <path 
            className={'stage-arrow-bc ' + getStageSVGClass(0)}
            onMouseEnter={getSetHoverStageFunc(0, 'onBackground')}
            onMouseLeave={getUnsetHoverStageFunc(0, 'onBackground')} 
            onClick={getClickOnStageFunc(0)}
            d="M231 98.3822L265.094 49.19L231.002 0.0013571C231.27 0.000452609 231.537 0 231.805 0C318.535 0 394.151 47.5921 433.959 118.086L403.721 170.054L344.368 159.97C320.614 122.927 279.076 98.3798 231.805 98.3798C231.536 98.3798 231.268 98.3806 231 98.3822Z"  
          />
          <circle 
            className={'stage-arrow-circle ' + getStageSVGClass(0)}
            onMouseEnter={getSetHoverStageFunc(0, 'onCircle')}
            onMouseLeave={getUnsetHoverStageFunc(0, 'onCircle')}
            onClick={getClickOnStageFunc(0)}
            cx="333.805" 
            cy="84" 
            r="30" 
            fill="#FFAB2B"
          />
          <path 
            className={'stage-arrow-number ' + getStageSVGClass(0)}
            onMouseEnter={getSetHoverStageFunc(0, 'onNumber')}
            onMouseLeave={getUnsetHoverStageFunc(0, 'onNumber')}
            onClick={getClickOnStageFunc(0)}
            d="M333.091 74.7438V99.9998H338.134V70.0288H326.531V74.7438H333.091Z" 
            fill="#106FA8"
          />
          
          { /* STAGE 2 */ }
          <path 
            className={'stage-arrow-bc ' + getStageSVGClass(1)}
            onMouseEnter={getSetHoverStageFunc(1, 'onBackground')}
            onMouseLeave={getUnsetHoverStageFunc(1, 'onBackground')} 
            onClick={getClickOnStageFunc(1)}
            d="M352 173.81C360.478 191.362 365.23 211.051 365.23 231.85C365.23 250.109 361.567 267.513 354.937 283.367L380.107 336.027L441.58 330.652C455.709 300.677 463.609 267.186 463.609 231.85C463.609 195.707 455.345 161.495 440.602 131L409.963 183.658L352 173.81Z" 
          />
          <circle
            className={'stage-arrow-circle ' + getStageSVGClass(1)}
            onMouseEnter={getSetHoverStageFunc(1, 'onCircle')}
            onMouseLeave={getUnsetHoverStageFunc(1, 'onCircle')}
            onClick={getClickOnStageFunc(1)}
            cx="410.609" 
            cy="251.85" 
            r="30" 
            fill="#FFAB2B"
          />
          <path 
            className={'stage-arrow-number ' + getStageSVGClass(1)}
            onMouseEnter={getSetHoverStageFunc(1, 'onNumber')}
            onMouseLeave={getUnsetHoverStageFunc(1, 'onNumber')}
            onClick={getClickOnStageFunc(1)}
            d="M421.711 266.809V261.93H409.247L414.659 257.297C419.21 253.402 421.506 250.245 421.506 246.063C421.506 239.995 417.529 236.346 411.133 236.346C404.778 236.346 400.596 240.61 400.555 247.17H405.762C405.803 243.316 407.853 241.02 411.133 241.02C414.29 241.02 416.135 242.947 416.135 246.35C416.135 249.22 414.782 251.188 410.723 254.632L400.924 262.914V266.85L421.711 266.809Z" 
            fill="#106FA8"
          />

          { /* STAGE 3 */ }
          <path 
            className={'stage-arrow-bc ' + getStageSVGClass(2)}
            onMouseEnter={getSetHoverStageFunc(2, 'onBackground')}
            onMouseLeave={getUnsetHoverStageFunc(2, 'onBackground')} 
            onClick={getClickOnStageFunc(2)}
            d="M435.373 344.115L373.071 349.562L348.425 298C327.722 334.519 290.319 360.326 246.595 365.042L214 412.072L249.673 463.543C329.776 457.545 398.539 410.872 435.373 344.115Z"
          />
          <circle 
            className={'stage-arrow-circle ' + getStageSVGClass(2)}
            onMouseEnter={getSetHoverStageFunc(2, 'onCircle')}
            onMouseLeave={getUnsetHoverStageFunc(2, 'onCircle')}
            onClick={getClickOnStageFunc(2)}
            cx="317.104" 
            cy="385.198" 
            r="30" 
            fill="#FFAB2B"
          />
          <path 
            className={'stage-arrow-number ' + getStageSVGClass(2)}
            onMouseEnter={getSetHoverStageFunc(2, 'onNumber')}
            onMouseLeave={getUnsetHoverStageFunc(2, 'onNumber')}
            onClick={getClickOnStageFunc(2)}
            d="M316.051 387.356C320.233 387.356 322.201 389.57 322.201 392.604C322.201 395.925 320.028 398.057 316.748 398.057C313.55 398.057 311.418 396.089 311.418 392.645H306.252C306.252 399.123 310.803 402.731 316.666 402.731C322.734 402.731 327.531 398.836 327.531 392.686C327.531 387.397 323.964 384.035 319.372 383.133L326.67 376.409V372.227H307.646V376.86H320.11L312.402 383.953V387.356H316.051Z" 
            fill="#106FA8"
          />

          { /* STAGE 4 */ }
          <path 
            className={'stage-arrow-bc ' + getStageSVGClass(3)}
            onMouseEnter={getSetHoverStageFunc(3, 'onBackground')}
            onMouseLeave={getUnsetHoverStageFunc(3, 'onBackground')} 
            onClick={getClickOnStageFunc(3)}
            d="M234.819 463.975L198.704 411.865L230.764 365.606C182.622 365.173 140.56 339.279 117.39 300.738L60.0766 291L29 344.409C68.5793 415.725 144.649 463.991 231.993 463.991C232.936 463.991 233.878 463.986 234.819 463.975Z"
          />
          <circle 
            className={'stage-arrow-circle ' + getStageSVGClass(3)}
            onMouseEnter={getSetHoverStageFunc(3, 'onCircle')}
            onMouseLeave={getUnsetHoverStageFunc(3, 'onCircle')}
            onClick={getClickOnStageFunc(3)}
            cx="128.993" 
            cy="377.991" 
            r="30" 
            fill="#FFAB2B"
          />
          <path 
            className={'stage-arrow-number ' + getStageSVGClass(3)}
            onMouseEnter={getSetHoverStageFunc(3, 'onNumber')}
            onMouseLeave={getUnsetHoverStageFunc(3, 'onNumber')}
            onClick={getClickOnStageFunc(3)}
            d="M140.209 387.293V382.701H136.56V363.021H129.959L116.388 382.701V387.293H131.599V392.992H136.56V387.293H140.209ZM121.964 382.701L131.599 368.679V382.701H121.964Z" 
            fill="#106FA8"
          />

          {/* STAGE 5 */}
          <path 
            className={'stage-arrow-bc ' + getStageSVGClass(4)}
            onMouseEnter={getSetHoverStageFunc(4, 'onBackground')}
            onMouseLeave={getUnsetHoverStageFunc(4, 'onBackground')} 
            onClick={getClickOnStageFunc(4)}
            d="M22.2359 331.352L53.6467 277.368L110.12 286.963C102.577 270.229 98.3798 251.661 98.3798 232.113C98.3798 213.256 102.286 195.311 109.334 179.043L83.502 125L23.5023 130.246C8.44982 160.997 0 195.568 0 232.113C0 267.621 7.9767 301.265 22.2359 331.352Z" 
          />
          <circle 
            className={'stage-arrow-circle ' + getStageSVGClass(4)}
            onMouseEnter={getSetHoverStageFunc(4, 'onCircle')}
            onMouseLeave={getUnsetHoverStageFunc(4, 'onCircle')}
            onClick={getClickOnStageFunc(4)}
            cx="50" 
            cy="205.113" 
            r="30"
            fill="#FFAB2B"
          />
          <path 
            className={'stage-arrow-number ' + getStageSVGClass(4)}
            onMouseEnter={getSetHoverStageFunc(4, 'onNumber')}
            onMouseLeave={getUnsetHoverStageFunc(4, 'onNumber')}
            onClick={getClickOnStageFunc(4)}
            d="M50.3032 221.606C56.9862 221.606 61.5372 217.178 61.5372 210.7C61.5372 204.796 57.5602 200.737 51.5742 200.737C49.5242 200.737 47.7202 201.27 46.2852 202.213L47.8842 195.981H59.7332V191.143H44.1122L40.4222 206.846L45.0552 207.83C46.1212 206.272 48.2122 205.124 50.5082 205.124C54.1162 205.124 56.2072 207.502 56.2072 210.987C56.2072 214.636 53.9522 216.932 50.4262 216.932C47.1052 216.932 45.0962 214.923 45.0962 211.52H39.8892C39.8892 217.588 44.0302 221.606 50.3032 221.606Z" 
            fill="#106FA8"
          />

          { /* STAGE 6 */ }
          <path 
            className={'stage-arrow-bc ' + getStageSVGClass(5)}
            onMouseEnter={getSetHoverStageFunc(5, 'onBackground')}
            onMouseLeave={getUnsetHoverStageFunc(5, 'onBackground')} 
            onClick={getClickOnStageFunc(5)}
            d="M215.905 1C136.217 6.30089 67.5372 51.8383 30 117.477L90.567 112.182L115.979 165.347C136.474 130.147 172.59 105.164 214.83 99.8958L249.638 49.6718L215.905 1Z"
          />
          <circle
            className={'stage-arrow-circle ' + getStageSVGClass(5)}
            onMouseEnter={getSetHoverStageFunc(5, 'onCircle')}
            onMouseLeave={getUnsetHoverStageFunc(5, 'onCircle')}
            onClick={getClickOnStageFunc(5)}
            cx="143.535" 
            cy="69.482" 
            r="30" 
            fill="#FFAB2B"
          />
          <path 
            className={'stage-arrow-number ' + getStageSVGClass(5)}
            onMouseEnter={getSetHoverStageFunc(5, 'onNumber')}
            onMouseLeave={getUnsetHoverStageFunc(5, 'onNumber')}
            onClick={getClickOnStageFunc(5)}
            d="M136.849 62.3257C133.487 66.8767 132.298 69.5007 132.298 73.1087C132.298 79.2587 137.136 84.0147 143.614 84.0147C150.01 84.0147 154.766 79.5457 154.766 73.1497C154.766 67.0817 150.584 62.7357 144.393 62.7357C143.655 62.7357 142.876 62.8587 142.138 63.0637L149.313 53.5107H143.327L136.849 62.3257ZM137.669 73.1497C137.669 69.5417 140.047 67.0407 143.614 67.0407C147.058 67.0407 149.477 69.5417 149.477 73.1497C149.477 76.7987 147.017 79.3407 143.614 79.3407C140.088 79.3407 137.669 76.7987 137.669 73.1497Z" 
            fill="#106FA8"
          />
        </svg>
      </div>

      <div className="legends">
        <div
          className={'legend ' + getStageClass(0)}
          ref={stageRefs[0]} 
          onClick={getClickOnStageFunc(0)}
          style={{marginTop: getTopOffset()}}
        >
          <header>
            <div>1. Stakesholders provide information on progress</div>

            <a className={"chevron-link " + getChevronClass(0)} onClick={() => setOpened(!opened)}>
              <i className="fa-light fa-chevron-down"></i>
            </a>
          </header>
          <Collapse 
            isOpened={opened && 0 === activeStage}
            theme={{collapse: 'legend-collapse', content: 'legend-content'}}
          >
            <p>
              Check out the deadlines for States and stakeholders to provide information: <a href="#">here</a>
            </p>
          </Collapse>
        </div>

        <div className={'legend ' + getStageClass(1)} ref={stageRefs[1]} onClick={getClickOnStageFunc(1)}>
          <header>
            <div>2. The UPR working group reviews the information</div>

            <a className={"chevron-link " + getChevronClass(1)} onClick={() => setOpened(!opened)}>
              <i className="fa-light fa-chevron-down"></i>
            </a>
          </header>
          <Collapse 
            isOpened={opened && 1 === activeStage}
            theme={{collapse: 'legend-collapse', content: 'legend-content'}}
          >
            <p>
              Lorem ipsum dolor sit amet, detraxit gubergren nec ei. Dicam mandamus in vix, sit novum maiestatis complectitur te, vide posse vituperatoribus sed ex. Primis gloriatur et qui. Eos ad doctus vidisse explicari, cum imperdiet percipitur ad. Te pri laudem everti persequeris.
            </p>

            <p>
              Lorem ipsum dolor sit amet, detraxit gubergren nec ei. Dicam mandamus in vix, sit novum maiestatis complectitur te, vide posse vituperatoribus sed ex. Primis gloriatur et qui. Eos ad doctus vidisse explicari, cum imperdiet percipitur ad. Te pri laudem everti persequeris.
            </p>
          </Collapse>
        </div>

        <div className={'legend ' + getStageClass(2)} ref={stageRefs[2]} onClick={getClickOnStageFunc(2)}>
          <header>
            <div>3. Lorem ipsum dolor sit amet</div>

            <a className={"chevron-link " + getChevronClass(2)} onClick={() => setOpened(!opened)}>
              <i className="fa-light fa-chevron-down"></i>
            </a>
          </header>
          <Collapse 
            isOpened={opened && 2 === activeStage}
            theme={{collapse: 'legend-collapse', content: 'legend-content'}}
          >
            <p>
              Check out the deadlines for States and stakeholders to provide information: <a href="#">here</a>
            </p>
          </Collapse>
        </div>

        <div className={'legend ' + getStageClass(3)} ref={stageRefs[3]} onClick={getClickOnStageFunc(3)}>
          <header>
            <div>4. Lorem ipsum dolor sit amet</div>

            <a className={"chevron-link " + getChevronClass(3)} onClick={() => setOpened(!opened)}>
              <i className="fa-light fa-chevron-down"></i>
            </a>
          </header>
          <Collapse 
            isOpened={opened && 3 === activeStage}
            theme={{collapse: 'legend-collapse', content: 'legend-content'}}
          >
            <p>
              Check out the deadlines for States and stakeholders to provide information: <a href="#">here</a>
            </p>
          </Collapse>
        </div>

        <div className={'legend ' + getStageClass(4)} ref={stageRefs[4]} onClick={getClickOnStageFunc(4)}>
          <header>
            <div>5. Lorem ipsum dolor sit amet</div>

            <a className={"chevron-link " + getChevronClass(4)} onClick={() => setOpened(!opened)}>
              <i className="fa-light fa-chevron-down"></i>
            </a>
          </header>
          <Collapse 
            isOpened={opened && 4 === activeStage}
            theme={{collapse: 'legend-collapse', content: 'legend-content'}}
          >
            <p>
              Check out the deadlines for States and stakeholders to provide information: <a href="#">here</a>
            </p>
          </Collapse>
        </div>

        <div className={'legend ' + getStageClass(5)} ref={stageRefs[5]} onClick={getClickOnStageFunc(5)}>
          <header>
            <div>6. Lorem ipsum dolor sit amet</div>

            <a className={"chevron-link " + getChevronClass(5)} onClick={() => setOpened(!opened)}>
              <i className="fa-light fa-chevron-down"></i>
            </a>
          </header>
          <Collapse 
            isOpened={opened && 5 === activeStage}
            theme={{collapse: 'legend-collapse', content: 'legend-content'}}
          >
            <p>
              Check out the deadlines for States and stakeholders to provide information: <a href="#">here</a>
            </p>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default StagesCircleDiagram;