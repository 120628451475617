// react imports
import React from "react";

// custom components
import Hero from "../common/hero/Hero";

// styles
import "./Recommendations.scss"
import RecommendationsFilterPanel from "./recommendations-filter-panel/RecommendationsFilterPanel";
import RecommendationList from "./recommendation-list/RecommendationList";

// images
import heroImg from "../../imgs/human-rights-mechanisms/human-rights-hero.jpg";

const Recommendations = (props) => {
  return (
    <div className="page-wrapper recommendations">
      <div className="container">
        <Hero
          title='Recommendations'
          backgroundImage={heroImg}
        />

        <div className="narrow-section">
          <RecommendationsFilterPanel extended={true} />
        </div>

        <div className="narrow-section">
          <RecommendationList
            recommendationClickable={true}
            isRecommendationsHighlightable={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Recommendations;