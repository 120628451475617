// react
import React from "react";
import { useNavigate } from "react-router";

// style
import "./Hero.scss";

/**
 * Props properties:
 * @param title: string
 * @param backgroundImage: string
 * @param heroLinks: array of { title: string, ref: object }
 * @param displayBackLink: boolean (default: false)
 */
const Hero = props => {
  // hooks
  const navigate = useNavigate();

  // decostruct parameters (and set the default values)
  const { 
    title = '', 
    backgroundImage = '',
    heroLinks = [], 
    displayBackLink = false 
  } = props;
  

  // helper funcs
  const scrollTo = link => {
    link.ref.current.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <div className="hero">
      <img src={backgroundImage} alt={title} />

      <div className="hero__content">
        <h1>{ title }</h1>
        
        {heroLinks.length > 0 && (
          <div className="hero__links">
            {heroLinks.map(link => (
              <a onClick={() => scrollTo(link)} key={link.title}>
                {link.title}
              </a>
            ))}
          </div>
        )}
  
        {displayBackLink && (
          <a className="chevron-link" onClick={() => navigate(-1)}>
            <i className="fa-light fa-chevron-left"></i>
          </a>
        )}
      </div>
    </div>
  );
};

export default Hero;