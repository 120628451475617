// react imports
import React from "react";

// prime imports
import { Editor } from "primereact/editor";
import { Button } from "primereact/button";

// custom components
import ActionMenu from "../action-menu/ActionMenu";

// styles
import "./Updates.scss"

/**
 * Props properties:
 * @param updateItems: array of {
 *  id: number
 *  screenshotUrl: string
 *  name: string
 *  action: string
 *  date: string
 *  details: string
 *  propertyName: string
 *  propertyValue: string
 * }
 * @returns 
 */
const Updates = (props) => {
  return (
    <div className="single-element-tab-content nrtd-updates">
      {props.updateItems.map(item => (
        <div className="nrtd-updates-item" key={item.id}>
          <div className="update-item-img-content">
            <img src={item.screenshotUrl} alt={item.name} />

            <div className="update-item-content">
              <div className="update-item-name-action">
                <span className="update-item-name">{item.name}</span>
                <span className="update-item-action">{item.action}</span>
              </div>

              {(item.propertyName && item.propertyValue) && (
                <div className="update-item-property">
                  <div className="property-name"> {item.propertyName} </div>
                  <div className="property-value"> {item.propertyValue} </div>
                </div>
              )}

              {item.details && (
                <div className="update-item-details">
                  {item.description}
                </div>
              )}
            </div>
          </div>

          <div className="update-item-date-actions">
            <span>{item.date}</span>
            <ActionMenu
              actions={[{ name: 'Action name 1' }, { name: 'Action name 2' }]}
              actionSelected={selectedActionName => console.log(selectedActionName)}
            />
          </div>
        </div>
      ))}

      {/* <Editor
        style={{ height: '100px' }}
        placeholder="Additional information on implementation progress"
      />

      <Button
        label="Submit Update"
        onClick={() => console.log('submit update')}
        className="submit-update"
      /> */}
    </div>
  );
};

export default Updates;