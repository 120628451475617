// react, react router imports
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";


// PRIMEREACT styles
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

// pages, layout
import Header from "./components/common/header/Header";
import Home from "./components/home/Home";
import Mechanisms from "./components/mechanisms/Mechanisms";
import UPR from "./components/mechanisms/upr/UPR";
import HumanRightsCommittee from "./components/mechanisms/human-rights-committee/HumanRightsCommittee";
import SpecialProcedures from "./components/mechanisms/special-procedures/SpecialProcedures";
import Recommendations from "./components/recommendations/Recommendations";
import Projects from "./components/projects/Projects";
import About from "./components/about/About";
import Statistics from "./components/statistics/Statistics";
import RecommendationPage from "./components/recommendations/recommendation-page/RecommendationPage";
import ProjectPage from "./components/projects/project-page/ProjectPage";

// style
import "./App.scss";
import ScrollToTop from "./apis/scroll-to-top";

function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <ScrollToTop />

        <Header />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/mechanisms' element={<Mechanisms />} />
          <Route path='/mechanisms/upr' element={<UPR />} />
          <Route path='/mechanisms/human-rights-committee' element={<HumanRightsCommittee />} />
          <Route path='/mechanisms/special-procedures' element={<SpecialProcedures />} />
          <Route path='/recommendations' element={<Recommendations />} />
          <Route path='/recommendations/:mechanismId/:dateString/:recommendationId' element={<RecommendationPage />} />
          <Route path='/follow-up-activities' element={<Projects />} />
          <Route path='/follow-up-activities/:projectId' element={<ProjectPage />} />
          <Route path='/statistics' element={<Statistics />} />
          <Route path='/about' element={<About />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
