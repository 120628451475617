// react imports
import React from "react";
import { Link, NavLink } from "react-router-dom";

// primereact components
import { Chart } from 'primereact/chart';

// custom components
import Hero from "../../common/hero/Hero";

// styles
// import "./Home.scss"

// images
import uprHero from "../../../imgs/universal-period-review/upr-hero.jpg";
import StagesCircleDiagram from "../../common/stages-circle-diagram/StagesCircleDiagram";

const UPR = () => {

  const chartData = {
    datasets: [
      {
        data: [300, 200],
        backgroundColor: [
          "#0A6AAE",
          "#B2CEDF"
        ]
      }
    ]
  };

  return (
    <div className="page-wrapper mechanism-page upr">
      <div className="container">
        <Hero
          title='Universal Periodic Review'
          backgroundImage={uprHero}
          displayBackLink={true} 
        />

        <div className="mechanism-columns">
          <div className="mechanism-content">
            <p>The <a href="#">Universal Periodic Review (UPR)</a> is a peer review mechanism, whereby Member States of the UN review each other’s human rights records. Every State shares the actions it has undertaken to fulfil their human rights obligations. Following this, the other States issue recommendations for continued improvement.</p>
  
            <p>Each State is reviewed every 4.5 years.</p>
  
            <p>The UPR reviews each Member State based on information provided by: </p>
            <ul>
              <li>The State, in the form of a “national report”.</li>
              <li>Independent human rights experts and groups (Special Procedures, human rights treaty bodies, and other UN entities).</li>
              <li>Other stakeholders, including national human rights institutions and non-governmental organizations.</li>
            </ul>
            
            <h2> The UPR review </h2>
  
            <h3>
              1. Stakeholders provide information on progress
            </h3>
  
            <p>Check out the deadlines for States and stakeholders to provide information: <a href="#">here</a></p>
  
            <h3>2. The UPR Working Group reviews the information</h3>
  
            <p>
              An interactive dialogue is held between <strong>Utopia</strong> and the Working Group on the UPR, composed of the 47 Member States of the Human Rights Council. Following the review by the Working Group, an outcome report is published with the questions, comments and recommendations made by other States to <strong>Utopia</strong>, as well as the responses of the State.
            </p>
  
            <h3>3. Stakeholders provide information on progress</h3>
  
            <p><strong>Utopia</strong> has the opportunity to make preliminary comments on the recommendations received, choosing to either accept/support or note them.</p>
  
            <p>Explore the <a href="#">197 recommendations</a> received by <strong>Utopia</strong> from the latest UPR review or check out the outcome report of the Working group: <a href="#">here</a>  </p>
  
            <h3>4. Stakeholders provide information on progress</h3>
  
            <p><a href="#">Utopia implements recommendations received</a></p>
  
            <p><strong>Utopia</strong> has the primary responsibility to implement the recommendations contained in the outcome report. During the next review, the State is expected to provide information on what they have been doing to implement the recommendations received.</p>
          </div>
  
          <div className="mechanism-side-panel">
            <div className="side-panel-info">
              <label>Utopia's next review</label>
              <div className="value">2024</div>
            </div>
            <div className="side-panel-info">
              <label>Utopia's last review</label>
              <div className="value">2019</div>
            </div>
  
            <Chart 
              type="pie" 
              data={chartData} 
              style={{ position: 'relative', width: '110px' }}
              options={{borderWidth: 0}}
            />
  
            <div className="side-panel-info multiple">
              <div className="col">
                <label>Documents</label>
                <div className="value">
                  12
                  <a href="#"><i className="fa-regular fa-arrow-up-right-from-square"></i></a>
                </div>
              </div>
              <div className="col">
                <label>Supported</label>
                <div className="value">186</div>
              </div>
            </div>
            <div className="side-panel-info">
              <label>Recommendations received from UPR</label>
              <div className="value">197</div>
            </div>
          </div>
        </div>

        <StagesCircleDiagram />
      </div>
    </div>
  );
};

export default UPR;