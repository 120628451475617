// importa reacts
import React from "react";

// primereact imports
import { Dropdown } from 'primereact/dropdown';

// styles
import "./ActionMenu.scss";

/**
 * Properties:
 * @param actions: array of { name: string, iconCLass?: string }
 * @param actionSelected: (selectedActionName:string) => {}
 * @param customPanelClassName?: string
 */
const ActionMenu = (props) => {
  const actionTemplate = (option, props) => {
    return (
      <div className="action-item">
        {option.iconClass && <i className={option.iconClass}></i>}
        {option.name}
      </div>
    );
  };

  const panelClasses = ['action-menu-panel'];
  if (props.customPanelClassName) {
    panelClasses.push(props.customPanelClassName);
  }

  return (
    <Dropdown
      className="action-menu"
      panelClassName={panelClasses.join(' ')}
      value=""
      optionLabel="name"
      options={props.actions}
      onChange={(e) => props.actionSelected(e.value.name)}
      dropdownIcon="fa-solid fa-ellipsis"
      itemTemplate={actionTemplate}
    />
  );
};

export default ActionMenu;