// react imports
import React from "react";

// styles
import "./Bars.scss"

/**
 * Props properties:
 * @param bars: array of [{ percentage: number, color: string }]
 * @returns 
 */
const Bars = (props) => {
  return (
    <div className="nrtd-bars">
      {props.bars.map((bar, barIndex) => (
        <div className="bar" key={`bar-${barIndex}`}>
          {bar.map((part, partIndex) => (
            <div
              key={`bar-${barIndex}-part-${partIndex}`} 
              style={{width: `${part.percentage}%`, backgroundColor: part.color}}></div>
          ))}
        </div> 
      ))}
    </div>
  );
};

export default Bars;